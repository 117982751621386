<template>
  <div>
    <div class="pageMain">
      <el-menu :default-active="searchForm.type" class="salesSummaryMenu" mode="horizontal" @select="chengeMenu">
        <el-menu-item index="0">按商品</el-menu-item>
        <el-menu-item index="1">按销售人员</el-menu-item>
      </el-menu>
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="单据日期：">
          <el-date-picker v-model="time" :default-time="['00:00:00','23:59:59']" @change="changeTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择路线" v-if="searchForm.type==0">
          <el-select v-model="wayIds" multiple filterable clearable style="width: 200px">
            <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择区域:" v-if="searchForm.type==0">
          <el-cascader ref="tree" style="width: 300px" :options="regionalRouteData" v-model="searchRegionalList" placeholder="请选择区域" :props="cascaderProps" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="销售人员:" v-if="searchForm.type==1">
          <el-select v-model="searchForm.driverId" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in driverList" :key="index" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品:">
          <el-select filterable v-model="goodsIds" multiple clearable :reserve-keyword="true" placeholder="请选择商品（可多选）" style="width: 250px" @change="searchGoodsMore">
            <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类别：" v-if="searchForm.type==0">
          <el-cascader :options="goodsType" v-model="searchForm.categoryId" :show-all-levels="false" placeholder="请选择商品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable @change="cascaderChange"></el-cascader>
        </el-form-item>
        <el-form-item label="仓库:">
          <el-select v-model="searchForm.warehouseId" filterable placeholder="可搜索选项">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-checkbox style="margin-left:20px" v-model="checked" @change="changeCheckbox">计算毛利</el-checkbox>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">销售汇总列表</p>
        <div class="tableTopBtn">
          <el-button size="mini" type="primary" @click="exportData" v-if="searchButton == '1'">导出</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" height="45vh" :data="tableData" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%;min-height: 35vh;" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column prop="driverName" label="销售人" v-if="searchForm.type==1">
              <template slot-scope="scope">
                <span class="orderId" @click="goSalesDetail(scope.row,1)">{{scope.row.driverName}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="code" label="商品编码">
            </el-table-column> -->
            <el-table-column prop="title" label="名称">
              <template slot-scope="scope">
                <span class="orderId" @click="goSalesDetail(scope.row,0)" v-if="searchForm.type==0">{{scope.row.title}}</span>
                <span v-else>{{scope.row.title}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="categoryName" label="商品类别">
            </el-table-column>
            <el-table-column prop="standard" label="规格型号">
            </el-table-column>
            <el-table-column prop="warehouseName" label="仓库">
            </el-table-column>
            <el-table-column prop="unit" label="单位">
            </el-table-column>
            <el-table-column prop="num" label="数量">
            </el-table-column>
            <el-table-column prop="remark" label="现称个数">
            </el-table-column>
            <el-table-column prop="price" label="单价">
              <template slot-scope="scope">
                {{ scope.row.price }} 元
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="销售金额">
              <template slot-scope="scope">
                {{ scope.row.totalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="costPrice" label="单价成本" v-if="searchForm.isQuery">
              <template slot-scope="scope">
                {{ scope.row.costPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="costTotalPrice" label="销售成本" v-if="searchForm.isQuery">
              <template slot-scope="scope">
                {{ scope.row.costTotalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="saleGrossProfit" label="销售毛利" v-if="searchForm.isQuery">
              <template slot-scope="scope">
                {{ scope.row.saleGrossProfit }} 元
              </template>
            </el-table-column>
            <el-table-column prop="grossMargin" label="毛利率" v-if="searchForm.isQuery">
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            当前第 {{ pagesData.currentPage }} 页，共 {{ pagesData.pages }} 页 {{ pagesData.total }} 条
            <el-select v-model="pagesData.currentRows" @change="handleSizeChange" placeholder="请选择每页条数">
              <el-option :value="10">10条/页</el-option>
              <el-option :value="50">50条/页</el-option>
              <el-option :value="100">100条/页</el-option>
              <el-option :value="200">200条/页</el-option>
              <el-option :value="500">500条/页</el-option>
              <el-option :value="1000">1000条/页</el-option>
            </el-select>
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination> -->
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import * as math from "mathjs"
export default {
  data() {
    return {
      searchRegionalList: [],
      cascaderProps: { multiple: true, children: 'childs', checkStrictly: false, },
      regionalRouteData: [],
      wayIds: [],
      wayList: [],
      goodsTypeId: [],
      goodsIds: [],
      selectGoods: [],
      time: [],
      loading: true,
      checked: false,
      //搜索
      searchForm: {
        categoryId: "",//商品类别id
        driverId: "",//销售人员（司机id）
        startTime: "",
        endTime: "",
        goodsId: "",//商品id
        type: "0",
        warehouseId: "",//仓库id
        isQuery: 0
      },
      tableData: [],
      goodsType: [],
      //分页数据
      pagesData: {
        pages: 0,
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      searchButton: '',
      updateButton: '',
      addButton: '',
      delButton: '',
      infoButton: '',
      adcode: sessionStorage.getItem('adcode'),
      warehouseList: [],//仓库列表
      driverList: [],
      lazyFlag: true,
      changeSize: '', //每页条数
      // 每页条数切换标识
      sizeFlag: false,
    }
  },
  created() {

    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'tWxbzMrjwJcszJx_update') {
        this.updateButton = '1'
      } else if (item.authority == 'print_records_query') {
        this.searchButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_add') {
        this.addButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_delete') {
        this.delButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_info') {
        this.infoButton = '1'
      }
    });
  },
  mounted() {
    this.getTime()
    this.getSelectGoods(this.adcode)
    this.getGoodsType()
    this.getWarehouseList()
    this.getDriverList()
    this.getWayList()
    this.getRegionalRouteList()
    // 表格懒加载
    let table = this.$refs.multipleTable.$el;

    table.addEventListener('scroll', (res) => {

      if (Number((res.target.scrollTop).toFixed(2)) + res.target.clientHeight >= res.target.scrollHeight - 20) {
        if (this.sizeFlag) { return }
        if (this.lazyFlag == true && this.tableData.length < this.pagesData.total && this.pagesData.currentPage < this.pagesData.pages) {
          this.$set(this.pagesData, 'currentPage', this.pagesData.currentPage + 1)
          this.listEvent();
          this.lazyFlag = false
        }
      }
    }, true)
  },
  methods: {
    // 处理数据方法
    changeKey(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].value = arr[i].id
        arr[i].label = arr[i].name
        if (arr[i].childs.length) {
          this.changeKey(arr[i].childs)
        } else {
          delete arr[i].childs
        }
      }
      return arr
    },
    // 区域列表
    async getRegionalRouteList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/regionalRoute/selectRegionalTree", {
          params: {
            current: 1,
            size: 1000
          }
        })
        .then(function (res) {
          if (res.status == 200) {
            if (res.data.length > 0) {
              // 递归 如果当前层级没有下级 则删除childs
              var newTree = _this.changeKey(res.data)
              _this.$nextTick(() => {
                _this.regionalRouteData = newTree
              })
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 路线列表
    getWayList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/driverWay")
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.wayList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //跳转销售明细
    goSalesDetail(row, flag) {
      row.flag = flag
      row.startTime = this.searchForm.startTime
      row.endTime = this.searchForm.endTime
      this.$router.push({ name: "salesDetail", params: row })
    },
    //导出
    exportData() {
      if (this.searchForm.type == 0) {
        var treedata = this.$refs.tree.getCheckedNodes();
        let ids = []
        for (let i in treedata) {
          if (!treedata[i].children.length) {
            ids.push(treedata[i].value)
          }
        }
        this.searchForm.regionalId = ids.join(",")
      }
      let _this = this;
      let data = JSON.parse(JSON.stringify(this.searchForm))
      delete data.current
      delete data.size
      this.$axios
        .get(this.$axios.defaults.basePath + "/printRecords/exportSalesSummaryGoods", {
          params: data,
          responseType: 'blob'
        })
        .then((res) => {
          var eleLink = document.createElement("a");
          eleLink.download = "销售汇总表（" + (this.searchForm.type == 0 ? "按商品" : this.searchForm.type == 1 ? "按销售员" : "") + "）.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          // 然后移除
          document.body.removeChild(eleLink);
        })

    },
    changeCheckbox(e) {
      this.searchForm.isQuery = e ? 1 : 0
      this.$set(this.pagesData, 'currentPage', 1)
      this.tableData = []
      setTimeout(() => {
        this.listEvent()
      }, 300)
    },
    getTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1);
      let day = String(date.getDate());
      let hour = String(date.getHours());
      let minutes = String(date.getMinutes());
      let seconds = String(date.getSeconds())
      month = month.length === 2 ? month : 0 + month;
      day = day.length === 2 ? day : 0 + day;
      hour = hour.length === 2 ? hour : 0 + hour;
      minutes = minutes.length === 2 ? minutes : 0 + minutes;
      seconds = seconds.length === 2 ? seconds : 0 + seconds;
      let startTime = `${year}-${month}-01 00:00:00`;
      let endTime = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
      let time = [startTime, endTime]
      this.time = time
      this.searchForm.startTime = startTime
      this.searchForm.endTime = endTime
      this.listEvent();
    },
    // 获取司机下拉列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.data.errcode == 0) {
            let obj = {
              username: "自提",
              id: "0"
            }
            res.data.data.unshift(obj)
            _this.driverList = res.data.data;
          }
        });
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.status == 200) {
            _this.warehouseList = res.data.data;
          }
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    chengeMenu(e) {
      this.searchForm.type = e
      if (e == 0) {
        this.searchForm.driverId = ""
      } else if (e == 1) {
        this.searchForm.categoryId = ""
      }
      this.tableData = []
      this.$set(this.pagesData, 'currentPage', 1)
      this.$set(this.pagesData, 'pages', 0)
      this.listEvent()
    },
    // 搜索商品多选
    searchGoodsMore(e) {
      this.searchForm.goodsId = e.join(',')
    },
    goSalesDelivery(row) {
      this.$router.push({ name: "salesDelivery", params: row })
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let ml = null //销售毛利
      let sr = null //销售收入
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property == "num" || column.property == "totalPrice" || column.property == "costPrice" || column.property == "costTotalPrice" || column.property == "saleGrossProfit") {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return Number(math.add(prev, curr)).toFixed(2);
              } else {
                return prev;
              }
            }, 0);
            // sums[index] += ' 元';
          } else {
            //   sums[index] = 'N/A';
          }
          if (column.property == "totalPrice") {
            sr = sums[index]
          }
          if (column.property == "saleGrossProfit") {
            ml = sums[index]
          }
        }
        if (column.property == "grossMargin") {
          sums[index] = String(((ml / sr) * 100).toFixed(2)) + '%'
        }
      });

      return sums;
    },
    getSelectGoods(adcode) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            adcode,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
            });
            _this.exportGoods = list;
            _this.selectGoods = list;
          }
        });
    },
    changeTime(e) {
      if (!e || e.length == 0) {
        this.searchForm.startTime = ""
        this.searchForm.endTime = ""
      } else {
        this.searchForm.startTime = e[0]
        this.searchForm.endTime = e[1]
      }
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.loading = true;
      _this.searchForm.current = _this.pagesData.currentPage
      _this.searchForm.size = _this.pagesData.currentRows
      _this.searchForm.wayId = _this.wayIds.length ? _this.wayIds.join(',') : '';
      this.$axios.get(_this.$axios.defaults.basePath + '/printRecords/selectSalesSummary', {
        params: _this.searchForm
      }).then(function (res) {
        _this.loading = false;
        if (res.data.errcode == 0) {
          if (_this.tableData.length == 0) {
            _this.tableData = res.data.data.records;
          } else {
            _this.tableData = _this.tableData.concat(res.data.data.records);
          }
          _this.pagesData.total = res.data.data.total;
          _this.pagesData.pages = res.data.data.pages;

          if (_this.lazyFlag == false && _this.pagesData.currentPage == _this.pagesData.pages) {
            _this.$message({
              message: '已经是最后一页了',
              type: 'info'
            })
          }
          _this.$nextTick(() => {
            _this.$refs.multipleTable.doLayout();
          })
          _this.lazyFlag = true
          _this.sizeFlag = false
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.tableData = []
      _this.$set(_this.pagesData, 'currentPage', 1)
      _this.sizeFlag = true
      _this.listEvent()
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent()
    },
    //搜索操作
    searchSubmit() {
      if (this.searchForm.type == 0) {
        var treedata = this.$refs.tree.getCheckedNodes();
        let ids = []
        for (let i in treedata) {
          if (!treedata[i].children.length) {
            ids.push(treedata[i].value)
          }
        }
        this.searchForm.regionalId = ids.join(",")
      }

      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.tableData = []
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => {
        if (key != "type") {
          this.searchForm[key] = ''
        }
      })
      this.searchRegionalList = []
      this.wayIds = []
      this.checked = false
      this.searchForm.isQuery = 0
      this.goodsIds = [];
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.tableData = []
      this.getTime()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },
  }
}
</script>

<style  scoped>
.orderId {
  color: #409eff;
  cursor: pointer;
}
.boxMain >>> .el-table .el-table__footer-wrapper tr td .cell {
  font-weight: 600;
}
.salesSummaryMenu {
  display: flex;
  justify-content: center;
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
}
.salesSummaryMenu .el-menu-item {
  margin-right: 30px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  height: 40px;
  line-height: 40px;
}
</style>